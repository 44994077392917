<script>
import CategoriesServiceApi from "@/service/api/categories";
import { required } from "vuelidate/lib/validators";
// import Switches from "vue-switches";
export default {
  props: ["mainCategoryData", "primaryCategoryData"],
  // components: { Switches },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 25, 50, 100],
      secondaryCategoryData: [],
      filter: null,
      filterOn: [],
      categoryDelete: null,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "primary", label: "Primary Category" },
      ],
      secondaryCategoryForm: {
        id: null,
        name: "",
        primary_id: null,
      },
      secondaryCategoryFormSubmitted: false,
    };
  },
  validations: {
    secondaryCategoryForm: {
      name: { required },
      primary_id: { required },
    },
  },
  computed: {
    primaryCategorySelect: function() {
      if (this.primaryCategoryData) {
        this.loadSecondaryCategory();
        return this.primaryCategoryData
          .filter((val) => val.status)
          .map((val) => {
            const options = {
              label: val.name,
              value: val.id,
            };
            return options;
          });
      }
      return [];
    },
    rows() {
      if (this.totalRows < this.secondaryCategoryData.length)
        return this.totalRows;
      else return this.totalRows;
    },
  },
  mounted() {
    this.loadSecondaryCategory();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formReset() {
      this.secondaryCategoryForm.id = null;
      this.secondaryCategoryForm.name = "";
      this.secondaryCategoryForm.primary_id = null;
      this.secondaryCategoryFormSubmitted = false;
    },
    loadSecondaryCategory() {
      CategoriesServiceApi.getSecondaryCategories().then((response) => {
        this.secondaryCategoryData = response.data.map((item) => {
          return {
            primary: item.primary,
            id: item.id,
            name: item.name,
            status: item.status === "active" ? true : false,
          };
        });
        this.totalRows = this.secondaryCategoryData.length;
        console.log(this.secondaryCategoryData);
      });
    },

    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // },
    addCategory() {
      this.secondaryCategoryFormSubmitted = true;
      this.$v.$touch();
      if (this.$v.secondaryCategoryForm.$invalid) {
        return;
      } else {
        const data = {
          action: "add",
          name: this.secondaryCategoryForm.name,
          primary_id: this.secondaryCategoryForm.primary_id,
        };
        CategoriesServiceApi.secondaryCategoryAction(data).then((response) => {
          if (response.data.success) {
            this.$refs["secondaryCategoryAdd"].hide();
            this.formReset();
            this.loadSecondaryCategory();
            this.categoryAddedMessage(response.data.secondary_category[0]);
          }
        });
      }
    },
    updateCategorySelect(item) {
      this.secondaryCategoryForm = {
        id: item.id,
        name: item.name,
        primary_id: item.primary.id,
      };
      console.log(this.secondaryCategoryForm);
    },
    categoryAddedMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name} is added! `, {
        title: `Secondary category added ${variant || "successfully"}`,
        variant: variant,
        solid: true,
      });
    },
    categoryToggleMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name} is ${data.status}! `, {
        title: `Secondary category status`,
        variant: variant,
        solid: true,
      });
    },
    categoryUpdateMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name} is updated! `, {
        title: `Primary category updated!`,
        variant: variant,
        solid: true,
      });
    },
    secondaryInactiveToggler(item_id = null) {
      const item = this.secondaryCategoryData.find(({ id }) => id === item_id);
      if (item) {
        const data = {
          action: "delete",
          secondary_id: item.id,
        };
        CategoriesServiceApi.secondaryCategoryAction(data).then((response) => {
          if (response.data.success) {
            this.loadSecondaryCategory();
            this.categoryToggleMessage(response.data.secondary_category);
          }
        });
      }
    },
    updateCategory(id) {
      this.secondaryCategoryFormSubmitted = true;
      this.$v.$touch();
      if (this.$v.secondaryCategoryForm.$invalid) {
        return;
      } else {
        if (id) {
          const data = {
            action: "edit",
            name: this.secondaryCategoryForm.name,
            secondary_id: id,
            primary_id: this.secondaryCategoryForm.primary_id,
          };
          CategoriesServiceApi.secondaryCategoryAction(data).then(
            (response) => {
              if (response.data.success) {
                this.$refs["secondaryCategoryAdd"].hide();
                this.formReset();
                this.loadSecondaryCategory();
                this.categoryUpdateMessage(response.data.secondary_category);
              }
            }
          );
        }
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Secondary Category</h4>
          <b-dropdown
            right
            toggle-class="arrow-none card-drop"
            class="float-right"
            variant="white"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-vertical"></i>
            </template>
            <b-dropdown-item v-b-modal.secondaryCategoryAdd
              >Add Secondary category</b-dropdown-item
            >
            <b-dropdown-item>---</b-dropdown-item>
          </b-dropdown>
          <div class="row mt-4" style="padding-top:10px;">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="secondaryCategoryData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(name)="row">
                <div>{{ row.item.name }}</div>

                <div>
                  <b-badge
                    :variant="
                      row.item.status === 'active' || row.item.status === true
                        ? 'success'
                        : 'danger'
                    "
                    >{{ row.item.status ? "Active" : "Inactive" }}</b-badge
                  >
                </div>
              </template>
              <template v-slot:cell(primary)="row">
                <div class="row">
                  <div class="col-6">
                    <div>{{ row.item.primary.name }}</div>

                    <div>
                      <b-badge
                        :variant="
                          row.item.primary.status === 'active'
                            ? 'success'
                            : 'danger'
                        "
                        >{{ row.item.primary.status }}</b-badge
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="action-btn">
                        <a
                          v-b-modal.secondaryCategoryAdd
                          v-on:click="updateCategorySelect(row.item)"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          data-toggle="tooltip"
                          title="Edit"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                      </div>
                      <div style="margin-top:5px;">
                        <b-form-checkbox
                          v-model="row.item.status"
                          name="check-button"
                          @change="secondaryInactiveToggler(row.item.id)"
                          switch
                        >
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>

            <div class="dataTables_paginate paging_simple_numbers">
              <ul class="pagination pagination-rounded mb-0 float-right">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="secondaryCategoryAdd"
      id="secondaryCategoryAdd"
      centered
      :title="
        secondaryCategoryForm.id
          ? 'Update secondary category'
          : 'Add secondary category'
      "
      hide-footer
    >
      <form class="needs-validation" @submit.prevent>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Category Name</label>
              <input
                type="text"
                v-model="secondaryCategoryForm.name"
                class="form-control"
                placeholder="Name English"
                value=""
                :class="{
                  'is-invalid':
                    secondaryCategoryFormSubmitted &&
                    $v.secondaryCategoryForm.name.$error,
                }"
              />
              <div
                v-if="
                  secondaryCategoryFormSubmitted &&
                    $v.secondaryCategoryForm.name.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.secondaryCategoryForm.name.required"
                  >Name is required!</span
                >
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label>Primary category</label>
              <FormulateInput
                v-model="secondaryCategoryForm.primary_id"
                type="select"
                :options="primaryCategorySelect"
                placeholder="Choose a primary
            category"
                :error="
                  secondaryCategoryFormSubmitted &&
                  $v.secondaryCategoryForm.primary_id.$error
                    ? 'Please select a primary category.'
                    : null
                "
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="!secondaryCategoryForm.id"
            type="button"
            v-on:click="addCategory()"
            class="btn btn-primary"
          >
            Add
          </button>
          <button
            v-else
            type="button"
            v-on:click="updateCategory(secondaryCategoryForm.id)"
            class="btn btn-primary"
          >
            Update
          </button>
          <button
            type="button"
            @click="
              $bvModal.hide('secondaryCategoryAdd');
              formReset();
            "
            class="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
