<script>
import CategoriesServiceApi from "@/service/api/categories";
import { required } from "vuelidate/lib/validators";
// import Switches from "vue-switches";
export default {
  props: ["mainCategoryData"],
  // components: { Switches },
  data() {
    return { 
      categoryData: [],
      filter: null,
      filterOn: [],
      categoryDelete: null,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name_en", sortable: true, label: "Name" },
        { key: "name_ar", label: "Arabic" },
        { key: "fullimageurl", label: "Image" },
      ],
      categoryForm: {
        id: null,
        name_en: "",
        name_ar: "",
        image_path:undefined,
      },
      categoryFormsubmitted: false,
    };
  },
  validations: {
    categoryForm: {
      name_en: { required },
      name_ar: { required },
    },
  },
  computed: {
    categorySelect: function() {
      if (this.mainCategoryData) {
        return this.mainCategoryData.map((val) => {
          const options = {
            label: `${val.name_en} - ${val.name_ar}`,
            value: val.id,
          };
          return options;
        });
      }
      return [];
    },
    rows() {
      if (this.totalRows < this.categoryData.length) return this.totalRows;
      else return this.totalRows;
    },
  },
  mounted() {
    this.loadCategory();
  },
  methods: {
    formReset() {
      this.categoryForm.id = null;
      this.categoryForm.name_en = "";
      this.categoryForm.name_ar = "";
      this.categoryFormsubmitted = false;
    },
    loadCategory() {
      CategoriesServiceApi.getPrimaryCategories().then((response) => {
        console.log("loaded",response.data);
        this.categoryData = response.data;
        this.categoryDelete = null;
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addCategory() {
      this.categoryFormsubmitted = true;
      this.$v.$touch();
      if (this.$v.categoryForm.$invalid) {
        return;
      } else {
        const data = new FormData();
        data.append("name_en", this.categoryForm.name_en);
        data.append("name_ar", this.categoryForm.name_ar);
        data.append("image", this.categoryForm.image_path.files[0].file);

        CategoriesServiceApi.primaryCategoryAction(data)
          .then((response) => {
      
              this.$refs["categoryAdd"].hide();
              this.formReset();
              this.loadCategory();
              this.categoryAddedMessage(response.data);
            
          })
          .catch((error) => {
            this.$bvToast.toast(`Error :( `, {
              title: `Category added failed: ${error}`,
              variant: "error",
              solid: true,
            });
            console.log("There is some error", error);
          });
      }
    },
    updateCategorySelect(item) {
      this.categoryForm = { ...item };
    },
    deleteCategoryNew(id) {
      CategoriesServiceApi.deleteCategory(id.id)
          .then((response) => {
            
            console.log('=-=-=-=-=-=-=-=RESPONSE FROM DELETING CATEGORY-=-=-=-=-=-=-=-');
            console.log(response);
            console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
          }).catch(err => {
            console.log(err);
          })
    },
    categoryAddedMessage(data, variant = "success") {
      console.log(data);
      this.$bvToast.toast(`${data.name_en} is added! `, {
        title: `Category added ${variant || "successfully"}`,
        variant: variant,
        solid: true,
      });
    },
    categoryToggleMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name_en} is ${data.status}! `, {
        title: `Primary category status`,
        variant: variant,
        solid: true,
      });
    },
    categoryUpdateMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name_en} is updated! `, {
        title: `Primary category updated!`,
        variant: variant,
        solid: true,
      });
    },
    primaryInactiveToggler(item = null) { 
      if (item) {
        CategoriesServiceApi.primaryCategoryAction(item).then((response) => {
          if (response.data.success) {
            this.loadCategory();
            this.categoryToggleMessage(response.data.primary_category);
          }
        });
      }
    },
    updateCategory(id) {
      this.categoryFormsubmitted = true;
      this.$v.$touch();
      if (this.$v.categoryForm.$invalid) {
        return;
      } else {
        if (id) {
        const data = new FormData();
        data.append("id", id);
        data.append("name_en", this.categoryForm.name_en);
        data.append("name_ar", this.categoryForm.name_ar);
        if(this.categoryForm.image_path&&this.categoryForm.image_path.files.length>0)
        data.append("image", this.categoryForm.image_path.files[0].file);
        
          CategoriesServiceApi.primaryCategoryAction(data).then(() => {
           
              this.$refs["categoryAdd"].hide();
              this.formReset();
              this.loadCategory();
          
          });
        }
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Primary Category</h4>
          <b-dropdown
            right
            toggle-class="arrow-none card-drop"
            class="float-right"
            variant="white"
          >
            <template v-slot:button-content>
              <i class="mdi mdi-dots-vertical"></i>
            </template>
            <b-dropdown-item v-b-modal.categoryAdd @click="formReset()"
              >Add Primary category</b-dropdown-item
            >
            <b-dropdown-item>---</b-dropdown-item>
          </b-dropdown>
          <div class="row mt-4" style="padding-top:10px;">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="categoryData"
              :fields="fields"
              responsive="sm" 
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(name_en)="row">
                <div>{{ row.item.name_en }}</div>

                <div>
                  <b-badge
                    :variant="
                      row.item.active === 'active' || row.item.active === true
                        ? 'success'
                        : 'danger'
                    "
                    >{{ row.item.active ? "Active" : "Inactive" }}</b-badge
                  >
                </div>
              </template>

            <template v-slot:cell(fullimageurl)="row">
                <div style="display: flex; flex-wrap: wrap;">
                  <div style="flex: 30%;">
                    <img :src="row.value" width="32" alt="Category Image" />
                  </div>
                  <div style="flex: 70%;text-align:left">
                    <div class="row">
              
                      <div class="col-4 action-btn">
                        <div class="row">
                          <div class="col-4">
                            <a
                              v-b-modal.categoryAdd
                              v-on:click="updateCategorySelect(row.item)"
                              class="mr-3 text-primary"
                              v-b-tooltip.hover
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i class="mdi mdi-pencil font-size-18"></i>
                            </a>
                          </div>
                          <div class="col-4">
                            <a
                              v-on:click="deleteCategoryNew(row.item)"
                              class="mr-3 text-primary"
                              v-b-tooltip.hover
                              data-toggle="tooltip"
                              title="Delete"
                            >
                              <i class="mdi mdi-delete font-size-18" style="color:#ff5c5c;"></i>
                            </a>
                          </div>
                          <div class="col-8" style="padding-top:5px;">
                            <b-form-checkbox
                              v-model="row.item.active"
                              name="check-button"
                              @change="primaryInactiveToggler(row.item)"
                              switch
                            >
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template> 
            </b-table>

          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="categoryAdd"
      id="categoryAdd"
      centered
      :title="
        categoryForm.id ? 'Update primary category' : 'Add primary category'
      "
      hide-footer
    >
      <form class="needs-validation" @submit.prevent>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Category Name</label>
              <input
                type="text"
                v-model="categoryForm.name_en"
                class="form-control"
                placeholder="Name English"
                value=""
                :class="{
                  'is-invalid':
                    categoryFormsubmitted && $v.categoryForm.name_en.$error,
                }"
              />
              <div
                v-if="categoryFormsubmitted && $v.categoryForm.name_en.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.categoryForm.name_en.required"
                  >Name is required!</span
                >
              </div>
              <input
                type="text"
                v-model="categoryForm.name_ar"
                class="form-control mt-2"
                placeholder="Name Arabic"
                value=""
                :class="{
                  'is-invalid':
                    categoryFormsubmitted && $v.categoryForm.name_ar.$error,
                }"
              />
              <div
                v-if="categoryFormsubmitted && $v.categoryForm.name_ar.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.categoryForm.name_ar.required"
                  >Name is required!</span
                >
              </div>
              <FormulateInput
                v-model="categoryForm.image_path"
                type="image"
                name="image_path"
                label="Select an image to upload"
                help="Select a png, jpg or gif to upload."
                validation="mime:image/jpeg,image/png,image/gif|required"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="!categoryForm.id"
            type="button"
            v-on:click="addCategory()"
            class="btn btn-primary"
          >
            Add
          </button>
          <button
            v-else
            type="button"
            v-on:click="updateCategory(categoryForm.id)"
            class="btn btn-primary"
          >
            Update
          </button>
          <button
            type="button"
            @click="
              $bvModal.hide('categoryAdd');
              formReset();
            "
            class="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal
      ref="categoryDelete"
      id="category-delete"
      centered
      title="Delete primary category"
      hide-footer
    >
      <p>
        Delete
      </p>
      <div class="modal-footer">
        <button
          type="button"
          v-on:click="deleteCategory()"
          class="btn btn-primary"
        >
          Please confirm before delete
        </button>
        <button
          type="button"
          @click="
            $bvModal.hide('category-delete');
            () => (brandDelete = null);
          "
          class="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </b-modal>
  </div>
</template>

<style>
.action-btn a {
  background-color: antiquewhite;
  padding: 3px;
  border-radius: 50%;
}
.action-btn a:hover {
  background-color: #f1f1f1;
}
</style>
