<script>
import Layout from "../../layouts/main"; 
import PrimaryCategory from "@/components/category/primary-category";
import SecondaryCategory from "@/components/category/secondary-category";
import CategoriesServiceApi from "@/service/api/categories";

export default {
  components: { Layout,  PrimaryCategory, SecondaryCategory },
  data() {
    return {
      categoryData: null,
      primaryCategoryData: null,
    };
  },
  mounted() {
    CategoriesServiceApi.getAllMainCategories().then((response) => {
      this.categoryData = response.data;
    });
  },
  methods: {
    primaryCategoryDataChanged(value) {
      this.primaryCategoryData = value;
    },
  },
};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-6">
        <PrimaryCategory
          :mainCategoryData="categoryData"
          v-on:primaryCategoryData="primaryCategoryDataChanged"
        />
      </div>
      <div class="col-lg-6">
        <SecondaryCategory :primaryCategoryData="primaryCategoryData" />
      </div>
    </div>
  </Layout>
</template>
